var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sellPricingDetails" },
    [
      _c("van-nav-bar", {
        attrs: {
          "left-arrow": "",
          fixed: "",
          border: false,
          "safe-area-inset-top": "",
          placeholder: "",
          title: "订单详情"
        },
        on: { "click-left": _vm.leftClick }
      }),
      _vm.sellPricingData
        ? _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "ctn" }, [
              _c("div", { staticClass: "flex topInfo color1a" }, [
                _c("div", { staticClass: "info" }, [
                  _vm._v("订单编号 : " + _vm._s(_vm.sellPricingData.oid))
                ]),
                _c(
                  "div",
                  {
                    staticClass: "info",
                    style: {
                      color:
                        _vm.sellPricingData.status == "UNDELIVERED"
                          ? "#FF2D2E"
                          : _vm.sellPricingData.status == "DONE" ||
                            _vm.sellPricingData.status == "VIOLATE"
                          ? "#02790D"
                          : "#1a1a1a"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.sellPricingData.status == "SUSPENSE"
                            ? "待审核"
                            : _vm.sellPricingData.status == "DONE" ||
                              _vm.sellPricingData.status == "VIOLATE"
                            ? "已完成"
                            : _vm.sellPricingData.status == "UNDELIVERED"
                            ? "待送货"
                            : _vm.sellPricingData.status == "FAIL"
                            ? "拒绝交易"
                            : _vm.sellPricingData.status == "RECEIVE"
                            ? "待处理"
                            : "已取消"
                        )
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "flex btmInfo color9" }, [
                _c("div", { staticClass: "time" }, [_vm._v("下单时间 ")]),
                _c("div", { staticClass: "info" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatDate_")(_vm.sellPricingData.createTime)
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "flex btmInfo color9" }, [
                _c("div", { staticClass: "time" }, [_vm._v("订单类型 ")]),
                _c("div", { staticClass: "marginRight" }, [
                  _vm._v(_vm._s(_vm.sellPricingData.goods[0].name) + " ")
                ]),
                _c("div", { staticClass: "icon" }, [_vm._v("卖料")])
              ]),
              _c("div", { staticClass: "flex btmInfo color9" }, [
                _c("div", { staticClass: "time" }, [_vm._v("单价 (元/克) ")]),
                _c("div", { staticClass: "color6" }, [
                  _vm._v(_vm._s(_vm.sellPricingData.goods[0].price.toFixed(2)))
                ])
              ]),
              _c("div", { staticClass: "flex btmInfo color9" }, [
                _c("div", { staticClass: "time" }, [_vm._v("定价重量 (克) ")]),
                _c("div", { staticClass: "color6" }, [
                  _vm._v(_vm._s(_vm.sellPricingData.goods[0].weight))
                ])
              ]),
              _c("div", { staticClass: "flex btmInfo color9" }, [
                _c("div", { staticClass: "time" }, [_vm._v("押金 (元) ")]),
                _c("div", { staticClass: "color6" }, [
                  _vm._v(
                    _vm._s(_vm.sellPricingData.earnestMoney.toFixed(2)) + " "
                  )
                ])
              ]),
              _c("div", { staticClass: "flex btmInfo color9 " }, [
                _c("div", { staticClass: "time" }, [_vm._v("保管费 (元)")]),
                _c("div", { staticClass: "color6" }, [
                  _vm._v(_vm._s(_vm.sellPricingData.storageFee))
                ])
              ])
            ]),
            _vm.sellPricingData.openId
              ? _c("div", { staticClass: "settlement" }, [
                  _c("div", { staticClass: "leftText" }, [_vm._v("结算单")]),
                  _c(
                    "div",
                    {
                      staticClass: "xiangqing",
                      on: {
                        click: function($event) {
                          return _vm.toDetails(_vm.sellPricingData)
                        }
                      }
                    },
                    [_vm._v("详情 "), _c("div", { staticClass: "icon" })]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _c("showPop", {
        ref: "pop",
        attrs: { showPop: _vm.showPop },
        on: {
          "update:showPop": function($event) {
            _vm.showPop = $event
          },
          "update:show-pop": function($event) {
            _vm.showPop = $event
          }
        }
      }),
      _vm.isbreak
        ? _c(
            "footer",
            { staticClass: "foot", on: { click: _vm.breakContract } },
            [_vm._v("无法交付现货怎么办？")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }